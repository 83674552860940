import {
  AuthenticityDiamondMultichome16 as SmallDiamond,
  AuthenticityDiamondMultichrome24 as MediumDiamond,
  AuthenticityDiamond32 as BigDiamond,
  VasBump32 as BumpRecommendation,
} from '@vinted/multichrome-icons'

export const getIconByName = (icon: string) => {
  if (icon === `${SmallDiamond.Title}${SmallDiamond.Size}`) return SmallDiamond
  if (icon === `${MediumDiamond.Title}${MediumDiamond.Size}`) return MediumDiamond
  if (icon === `${BigDiamond.Title}${BigDiamond.Size}`) return BigDiamond
  if (icon === `${BumpRecommendation.Title}${BumpRecommendation.Size}`) return BumpRecommendation

  return null
}
