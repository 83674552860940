'use client'

import { Icon } from '@vinted/web-ui'

import { getTestId } from 'libs/utils/testId'
import { IconBadgeModel } from 'types/models'

import { getIconByName } from '../utils'

type IconBadgeProps = {
  iconBadges: Array<IconBadgeModel>
  testId?: string
}

const ItemBoxIconBadges = ({ iconBadges, testId }: IconBadgeProps) => {
  if (!iconBadges?.length) return null

  return iconBadges.map(iconBadge => {
    const iconName = getIconByName(iconBadge.iconBig)

    if (!iconName) return null

    return (
      <div
        data-testid={getTestId(testId, `overlay-icon-${iconBadge.iconBig}`)}
        key={iconBadge.iconBig}
        className="new-item-box__overlay-icon"
      >
        <Icon name={iconName} aria={{ 'aria-label': iconBadge.label }} />
      </div>
    )
  })
}

export default ItemBoxIconBadges
